<template>
  <div class="pricing-section relative gray-80">
    <div class="relative" style="z-index: 2">
      <SectionTitle title="Plans and pricing" class="mb-5 mb-sm-3 text-center white--text"/>
      <p class="pricing-section-descr mb-0 mx-auto text-body-md text-center white--text">Choose the best plan for your business.</p>

      <div class="pricing-cards d-flex flex-column flex-md-row flex-wrap flex-md-nowrap align-center justify-center mt-5 mt-sm-14">
        <div class="pricing-card fill-width my-3 px-7 pt-8 pb-9 rounded gray-10 text-center gray-90--text">
          <h5 class="mb-5 text-header-2">Personal</h5>

          <p class="d-flex align-center justify-center mb-7 text-body-lg font-weight-medium">
            $12,99 <span class="ml-1 font-weight-regular text-captions-2" style="opacity: 0.5">/ User Monthly</span>
          </p>

          <v-list dense color="gray-10" class="pt-7 pb-6 text-left">
            <v-list-item class="align-start mb-5 px-0 font-weight-regular gray-50--text">
              <IconCheckFilled width="18" class="mr-2 gray-90--text"/>
              <p class="mb-0">Create personal dashboard.</p>
            </v-list-item>
            <v-list-item class="align-start mb-5 px-0 font-weight-regular gray-50--text">
              <IconCheckFilled width="18" class="mr-2 gray-90--text"/>
              <p class="mb-0">Organize your notes and <span class="font-weight-medium gray-90--text">workflows</span>.</p>
            </v-list-item>
            <v-list-item class="align-start px-0 font-weight-regular gray-50--text">
              <IconCheckFilled width="18" class="mr-2 gray-90--text"/>
              <p class="mb-0"><span class="font-weight-medium gray-90--text">5GB</span> of space.</p>
            </v-list-item>
          </v-list>

          <UiBtn
            color="accent"
            :width="$vuetify.breakpoint.xsOnly ? 236 : 150"
            :height="$vuetify.breakpoint.xsOnly ? 42 : 38"
            :class="{'text-capitalize': $vuetify.breakpoint.xsOnly}"
            :to="{name: 'Login'}">
            Choose this plan
            <IconArrowRight v-if="$vuetify.breakpoint.smAndUp" width="16" style="margin-top: 1px;" class="ml-2"/>
          </UiBtn>
        </div>



        <div class="pricing-card fill-width my-3 mx-md-10 px-7 pt-7 pb-9 rounded black-300 text-center gray-0--text">
          <div class="d-inline-block mb-4 rounded text-body-lg accent" style="padding: 6px;">-30%</div>

          <h5 class="mb-5 text-header-2">Pro Plan</h5>

          <p class="d-flex align-center justify-center mb-7 text-body-lg font-weight-medium">
            $24,90 <span class="ml-1 font-weight-regular text-captions-2" style="opacity: 0.5">/ User Monthly</span>
          </p>

          <v-list dense color="black-300" class="py-7 text-left">
            <v-list-item class="align-start mb-5 px-0 font-weight-regular gray-50--text">
              <IconCheckFilled width="18" class="mr-2 gray-0--text"/>
              <p class="mb-0">All features in <span class="font-weight-medium gray-0--text">Personal</span>.</p>
            </v-list-item>
            <v-list-item class="align-start mb-5 px-0 font-weight-regular gray-50--text">
              <IconCheckFilled width="18" class="mr-2 gray-0--text"/>
              <p class="mb-0">Unlock <span class="font-weight-medium gray-0--text">Teams</span> to create a work group.</p>
            </v-list-item>
            <v-list-item class="align-start px-0 font-weight-regular gray-50--text">
              <IconCheckFilled width="18" class="mr-2 gray-0--text"/>
              <p class="mb-0"><span class="font-weight-medium gray-0--text">200GB</span> of shared space.</p>
            </v-list-item>
          </v-list>

          <UiBtn
            color="accent"
            :width="$vuetify.breakpoint.xsOnly ? 236 : 150"
            :height="$vuetify.breakpoint.xsOnly ? 42 : 38"
            :class="{'text-capitalize': $vuetify.breakpoint.xsOnly}"
            :to="{name: 'Login'}">
            Choose this plan
            <IconArrowRight v-if="$vuetify.breakpoint.smAndUp" width="16" style="margin-top: 1px;" class="ml-2"/>
          </UiBtn>
        </div>



        <div class="pricing-card fill-width my-3 px-7 pt-8 pb-9 rounded gray-10 text-center gray-90--text">
          <h5 class="mb-5 text-header-2 font-weight-semi-bold">Enterprise</h5>

          <p class="d-flex align-center justify-center mb-7 text-body-lg font-weight-medium">
            $54,90 <span class="ml-1 text-captions-2 font-weight-regular" style="opacity: 0.5">/ User Monthly</span>
          </p>

          <v-list dense color="gray-10" class="py-7 text-left">
            <v-list-item class="align-start mb-5 px-0 font-weight-regular gray-50--text">
              <IconCheckFilled width="18" class="mr-2 gray-90--text"/>
              <p class="mb-0">All features in <span class="font-weight-medium gray-90--text">Pro Plan</span>.</p>
            </v-list-item>
            <v-list-item class="align-start mb-5 px-0 font-weight-regular gray-50--text">
              <IconCheckFilled width="18" class="mr-2 gray-90--text"/>
              <p class="mb-0">Unlock  <span class="font-weight-medium gray-90--text">Database</span> to manage your data.</p>
            </v-list-item>
            <v-list-item class="align-start px-0 font-weight-regular gray-50--text">
              <IconCheckFilled width="18" class="mr-2 gray-90--text"/>
              <p class="mb-0"><span class="d-inline font-weight-medium gray-90--text">500GB</span> / 5 shared spaces.</p>
            </v-list-item>
          </v-list>

          <UiBtn
            color="accent"
            :width="$vuetify.breakpoint.xsOnly ? 236 : 150"
            :height="$vuetify.breakpoint.xsOnly ? 42 : 38"
            :class="{'text-capitalize': $vuetify.breakpoint.xsOnly}"
            :to="{name: 'Login'}">
            Choose this plan
            <IconArrowRight v-if="$vuetify.breakpoint.smAndUp" width="16" style="margin-top: 1px;" class="ml-2"/>
          </UiBtn>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import SectionTitle from "@/components/landings/SectionTitle";
import UiBtn from "@/components/UI/UiBtn";

export default {
  name: "PricingSection",
  components: {
    IconCheckFilled: () => import('@/components/icons/IconCheckFilled'),
    IconArrowRight: () => import('@/components/icons/IconArrowRight'),

    SectionTitle,
    UiBtn
  },
}
</script>

<style scoped lang="scss">
.pricing-section {
  padding: 84px 40px 120px;

  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      mix-blend-mode: color-burn;
      background: transparent url("~@/assets/images/landings/background-pattern.png") repeat top left;
      z-index: 1;
    }
  }

  @media #{map-get($display-breakpoints, 'xs-only')} {
    padding: 48px 40px 56px;

    .pricing-section-descr {
      max-width: 294px;
    }
  }

  .pricing-card {
    max-width: 308px;

    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      max-width: 342px;
    }

    ::v-deep .v-list {
      border-top: 1px solid var(--v-gray-40-base) !important;

      .v-list-item {
        min-height: auto;
        font-size: 13px;
        letter-spacing: -0.4px;
        line-height: 18px;
      }
    }

    &:nth-child(2) {
      ::v-deep .v-list {
        border-top: 1px solid var(--v-gray-95-base) !important;
      }
    }
  }
}
</style>
