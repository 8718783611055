<template>
  <div class="faq-section">
    <div class="faq-section-grid fill-width mx-auto">
      <SectionTitle title="Frequently Asked <br> Questions (FAQ)"/>

      <v-expansion-panels
        accordion
        flat
        multiple
        class="faq-accordion"
      >
        <v-expansion-panel v-for="(item, idx) in faqData" :key="idx">
          <v-expansion-panel-header class="px-0 py-2 px-sm-8 py-sm-10">
            <span class="d-flex align-center mr-6 black--text">
              <strong class="mr-8 text-header-1">{{ item.number }}</strong>
              {{ item.question }}
            </span>
            <template v-slot:actions>
              <IconPlus width="18"/>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content eager class="text-body-md">
            {{ item.answer }}
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </div>
</template>

<script>
import SectionTitle from "@/components/landings/SectionTitle";
export default {
  name: "FAQSection",
  components: {
    IconPlus: () => import('@/components/icons/IconPlus'),

    SectionTitle
  },
  data() {
    return {
      faqData: [
        {number: "01", question: "What is UpSway?", answer: "Est elit, eu potenti imperdiet lectus. Semper orci, id adipiscing ornare. Ut eget enim purus amet. Vestibulum, suscipit dui vestibulum risus auctor sit sodales. Vitae ac et viverra ac a ultrices parturient. Condimentum semper tristique donec est. Rhoncus etiam vivamus integer quam sed. Pretium eget suscipit cursus nullam. Id enim suspendisse sapien nunc eget. Aliquet senectus nunc ac velit donec ac. Amet, tristique facilisi sit metus adipiscing."},
        {number: "02", question: "How it works?", answer: "Est elit, eu potenti imperdiet lectus. Semper orci, id adipiscing ornare. Ut eget enim purus amet. Vestibulum, suscipit dui vestibulum risus auctor sit sodales. Vitae ac et viverra ac a ultrices parturient. Condimentum semper tristique donec est. Rhoncus etiam vivamus integer quam sed. Pretium eget suscipit cursus nullam. Id enim suspendisse sapien nunc eget. Aliquet senectus nunc ac velit donec ac. Amet, tristique facilisi sit metus adipiscing."},
        {number: "03", question: "Will it work for me?", answer: "Est elit, eu potenti imperdiet lectus. Semper orci, id adipiscing ornare. Ut eget enim purus amet. Vestibulum, suscipit dui vestibulum risus auctor sit sodales. Vitae ac et viverra ac a ultrices parturient. Condimentum semper tristique donec est. Rhoncus etiam vivamus integer quam sed. Pretium eget suscipit cursus nullam. Id enim suspendisse sapien nunc eget. Aliquet senectus nunc ac velit donec ac. Amet, tristique facilisi sit metus adipiscing."},
        {number: "04", question: "Can I use it for my business?", answer: "Est elit, eu potenti imperdiet lectus. Semper orci, id adipiscing ornare. Ut eget enim purus amet. Vestibulum, suscipit dui vestibulum risus auctor sit sodales. Vitae ac et viverra ac a ultrices parturient. Condimentum semper tristique donec est. Rhoncus etiam vivamus integer quam sed. Pretium eget suscipit cursus nullam. Id enim suspendisse sapien nunc eget. Aliquet senectus nunc ac velit donec ac. Amet, tristique facilisi sit metus adipiscing."},
      ]
    }
  }
}
</script>

<style scoped lang="scss">
.faq-section {
  padding: 144px 40px 190px;

  @media #{map-get($display-breakpoints, 'xs-only')} {
    padding: 82px 20px 60px;
  }

  .faq-section-grid {
    max-width: 1066px;
  }

  .section-title {
    margin-bottom: 94px;

    @media #{map-get($display-breakpoints, 'xs-only')} {
      margin-bottom: 32px;
    }
  }

  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    .v-expansion-panel:not(:first-child) {
      border-top: 3px solid var(--v-accent-base);
    }
  }

  .v-expansion-panel-header {
    min-height: auto;
    font-size: 24px;
    line-height: 29px;

    strong {
      width: 36px;
      color: rgba(60, 60, 67, 0.5);
    }

    @media #{map-get($display-breakpoints, 'xs-only')} {
      font-size: 20px;
      line-height: 24px;
    }
  }

  ::v-deep .v-expansion-panel-header__icon {
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #F3F5F6;

    svg {
      color: #000!important;
      transition: transform 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
    }
  }

  .v-expansion-panel-header--active {
    ::v-deep .v-expansion-panel-header__icon {
      background-color: var(--v-accent-base);

      svg {
        transform: rotate(-135deg) !important;
        color: var(--v-gray-0-base)!important;
      }
    }
  }

  ::v-deep .v-expansion-panel-content__wrap {
    padding: 0 80px 40px 100px;
    color: rgba(60, 60, 67, 0.85);

    @media #{map-get($display-breakpoints, 'xs-only')} {
      padding: 8px 48px 24px 68px;
    }
  }
}
</style>
