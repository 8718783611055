<template>
  <div>
    <WelcomeSection/>
    <OurFeaturesSection/>

    <div class="users-feedback-section accent px-10 py-8 py-sm-16">
      <div class="mx-auto fill-width" style="max-width: 988px">
        <p class="white--text">“Everything I needed, when I needed it”</p>
        <span class="text-uppercase font-weight-black accent-20--text">What our users say.</span>
      </div>
    </div>

    <PricingSection/>
    <FunctionalitySection/>
    <GetStartedToday/>
    <FAQSection/>
  </div>
</template>

<script>
import WelcomeSection from "@/views/landings/WelcomeLanding/WelcomeSection";
import OurFeaturesSection from "@/views/landings/WelcomeLanding/OurFeaturesSection";
import PricingSection from "@/views/landings/WelcomeLanding/PricingSection";
import FunctionalitySection from "@/components/landings/FunctionalitySection";
import GetStartedToday from "@/components/landings/GetStartedToday";
import FAQSection from "@/views/landings/WelcomeLanding/FAQSection";

export default {
  name: "WelcomeLanding",
  components: {
    WelcomeSection,
    OurFeaturesSection,
    PricingSection,
    FunctionalitySection,
    GetStartedToday,
    FAQSection,
  }
}
</script>

<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Yeseva+One&display=swap');

.users-feedback-section {
  p {
    max-width: 700px;
    font-size: 64px;
    line-height: 65px;
    font-family: 'Yeseva One', cursive;
  }

  span {
    font-size: 14px;
  }

  @media #{map-get($display-breakpoints, 'xs-only')} {
    p {
      font-size: 36px;
      line-height: 37px;
    }

    span {
      font-size: 20px;
    }
  }
}
</style>
