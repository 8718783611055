<template>
  <div class="features-section relative overflow-hidden">
    <div class="features-section-grid relative px-5 px-sm-10 text-center">
      <div class="features-section-sub-title relative d-inline-block px-7 mb-6 text-center text-title-3 text-uppercase font-weight-regular accent--text">
        Our features
      </div>
      <SectionTitle title="Adapt to the project <br> Specific Needs" class="relative mb-13 mb-sm-11 pb-7"/>

      <div class="features-section-header-video relative d-flex align-center justify-center mx-auto rounded">
        <IconPlay v-if="$vuetify.breakpoint.smAndUp" class="white--text" width="36"/>
        <IconPlayCircle v-else class="white--text" width="74"/>
      </div>
    </div>

    <div class="slides-wrap block-separator d-flex justify-center mb-8 overflow-hidden relative">
      <div class="slides-grid d-flex flex-column flex-sm-row fill-width px-5 px-sm-0" :class="`slide-grid_${currentSlide}`">
        <div :class="['slide flex-column flex-sm-row slide_1 relative', $vuetify.breakpoint.xsOnly || currentSlide === 1 ? 'd-flex' : 'd-none' ]">
          <div class="slide-info flex-shrink-0 mr-sm-8">
            <div class="dot absolute"/>
            <h4>Your project <br> Your way</h4>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Et ut rutrum viverra amet justo. Egestas morbi vel imperdiet sed semper. Senectus ac eget
            </p>
          </div>
          <div class="slide-image d-flex align-center">
            <img :src="require('@/assets/images/landings/welcomeLanding/ourFeaturesSection/project-way.png')" alt="" class="fill-width">
          </div>
        </div>

        <div :class="['slide flex-column flex-sm-row slide_2 relative', $vuetify.breakpoint.xsOnly || currentSlide === 2 ? 'd-flex' : 'd-none' ]">
          <div class="dot absolute"/>
          <div class="slide-image d-flex align-center">
            <img :src="require('@/assets/images/landings/welcomeLanding/ourFeaturesSection/daily-routine.png')" alt="" class="fill-width">
          </div>
          <div class="slide-info flex-shrink-0">
            <h4>Daily Routine <br> Simplified</h4>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Et ut rutrum viverra amet justo. Egestas morbi vel imperdiet sed semper. Senectus ac eget
            </p>
          </div>
        </div>

        <div :class="['slide flex-column flex-sm-row slide_3 relative', $vuetify.breakpoint.xsOnly || currentSlide === 3 ? 'd-flex' : 'd-none' ]">
          <div class="dot absolute"/>
          <div class="slide-info flex-shrink-0">
            <h4>File <br> Management</h4>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Et ut rutrum viverra amet justo. Egestas morbi vel imperdiet sed semper. Senectus ac eget
            </p>
          </div>
          <div class="slide-image d-flex align-center">
            <img :src="require('@/assets/images/landings/welcomeLanding/ourFeaturesSection/file-managment.png')" alt="" class="fill-width">
          </div>
        </div>

        <div :class="['slide flex-column flex-sm-row slide_4 relative', $vuetify.breakpoint.xsOnly || currentSlide === 4 ? 'd-flex' : 'd-none' ]">
          <div class="dot absolute"/>
          <div class="slide-image d-flex align-center">
            <img :src="require('@/assets/images/landings/welcomeLanding/ourFeaturesSection/project-template.png')" alt="" class="fill-width">
          </div>
          <div class="slide-info flex-shrink-0">
            <h4>Project ready <br> out of the box</h4>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Et ut rutrum viverra amet justo. Egestas morbi vel imperdiet sed semper. Senectus ac eget
            </p>
          </div>
        </div>
      </div>
    </div>

    <div v-if="$vuetify.breakpoint.smAndUp" class="slide-controls d-flex align-center justify-space-between mx-auto relative">
      <div
        v-for="slide in 4"
        :key="slide"
        :class="['slide-control relative cursor-pointer', currentSlide === slide ? 'accent' : 'gray-45']"
        @click="currentSlide = slide"/>
    </div>

  </div>
</template>

<script>
import SectionTitle from "@/components/landings/SectionTitle";

export default {
  name: "OurFeaturesSection",
  components: {
    IconPlay: () => import('@/components/icons/IconPlay'),
    IconPlayCircle: () => import('@/components/icons/IconPlayCircle'),

    SectionTitle,
  },
  data() {
    return {
      currentSlide: 1,
      slides: [
        {
          title: "Your project <br> Your way",
          text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Et ut rutrum viverra amet justo. Egestas morbi vel imperdiet sed semper. Senectus ac eget"
        }
      ]
    }
  }
}
</script>

<style scoped lang="scss">
.features-section {
  padding: 40px 0 172px;

  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 442px;
      background-color: var(--v-gray-10-base);
    }
  }

  @media #{map-get($display-breakpoints, 'xs-only')} {
    padding: 40px 0 0;
  }

  .features-section-sub-title {
    z-index: 2;

    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        bottom: 0;
        z-index: -1;
        background-color: var(--v-gray-10-base);
      }
    }
  }

  .section-title {
    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 88px;
      height: 3px;
      background-color: var(--v-accent-base);
    }
  }

  .features-section-header-video {
    max-width: 600px;
    height: 360px;
    background-color: var(--v-gray-45-base);

    @media #{map-get($display-breakpoints, 'xs-only')} {
      background-color: var(--v-gray-90-base);
    }
  }

  .block-separator {
    &::before {
      content: "";
      position: absolute;
      top: 36px;
      left: 50%;
      transform: translateX(-50%);
      width: 2px;
      height: 91px;
      background: transparent url("~@/assets/images/landings/welcomeLanding/vertical-line.svg") no-repeat center center;
      background-size: contain;
    }
  }

  .slides-wrap {
    padding-top: 154px;

    .slide-grid_1 {
      max-width: 1380px;
      margin: 0 64px;
    }

    .slide-grid_2 {
      max-width: 1320px;
      margin: 0 100px;
    }

    .slide-grid_3 {
      max-width: 1232px;
      margin: 0 100px;
    }

    .slide-grid_4 {
      max-width: 1060px;
      margin: 0 100px;
    }

    .slides-grid {
      @media #{map-get($display-breakpoints, 'xs-only')} {
        margin: 0;
      }
    }

    .slide {
      height: 496px;
      background-color: #fff;

      @media #{map-get($display-breakpoints, 'sm-only')} {
        height: 380px;
      }

      @media #{map-get($display-breakpoints, 'xs-only')} {
        height: auto;
      }

      &::before,
      &::after {
        content: "";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 100vw;
        height: 2px;
        background-color: var(--v-accent-base);
      }

      .dot {
        top: 50%;
        transform: translateY(-50%);
        width: 26px;
        height: 26px;
        border-radius: 50%;
        background-color: var(--v-accent-base);
      }

      h4 {
        margin-bottom: 64px;
        font-size: 42px;
        line-height: 48px;
        color: var(--v-black-100-base);

        @media #{map-get($display-breakpoints, 'sm-and-down')} {
          margin-bottom: 32px;
          font-size: 32px;
          line-height: 38px;
        }
      }

      p {
        margin-bottom: 0;
        max-width: 308px;
        font-size: 20px;
        line-height: 20px;
        color: #000;

        @media #{map-get($display-breakpoints, 'sm-and-down')} {
          max-width: 250px;
          font-size: 16px;
        }

        @media #{map-get($display-breakpoints, 'xs-only')} {
          margin-bottom: 48px;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      img {
        max-width: 100%;
        max-height: 100%;
      }

      &_1 {
        max-width: 1028px;

        .dot,
        &::before,
        &::after {
          left: 101%;
        }

        .slide-info {
          padding-top: 78px;

          @media #{map-get($display-breakpoints, 'xs-only')} {
            background-color: var(--v-gray-10-base);
          }
        }
      }

      &_2 {
        max-width: 1028px;

        &::before {
          right: 100%;
        }

        .dot,
        &::after {
          left: 104%;
        }

        .slide-info {
          padding-top: 78px;

          @media #{map-get($display-breakpoints, 'xs-only')} {
            background-color: var(--v-gray-30-base);
          }
        }
      }

      &_3 {
        max-width: 1132px;

        &::before {
          right: 104%;
        }

        .dot,
        &::after {
          left: 104%;
        }

        .slide-info {
          padding: 60px 100px 0 0;
          @media #{map-get($display-breakpoints, 'sm-and-down')} {
            padding: 100px 50px 0 0;
          }

          @media #{map-get($display-breakpoints, 'xs-only')} {
            background-color: var(--v-accent-10-base);
          }
        }
      }

      &_4 {
        max-width: 1000px;

        .dot,
        &::before,
        &::after {
          right: 104%;
        }

        .slide-info {
          padding: 60px 0 0 100px;

          @media #{map-get($display-breakpoints, 'sm-and-down')} {
            padding: 100px 0 0 50px;
          }

          @media #{map-get($display-breakpoints, 'xs-only')} {
            background-color: var(--v-gray-45-base);
          }
        }
      }

      @media #{map-get($display-breakpoints, 'xs-only')} {
        .dot,
        &::after {
          display: none;
        }

        &_2,
        &_4,
        &_3 {
          &::before {
            content: "";
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            top: -36px;
            width: 2px;
            height: 91px;
            background: transparent url("~@/assets/images/landings/welcomeLanding/vertical-line.svg") no-repeat center center;
            background-size: contain;
          }
        }

        .slide-info {
          height: 264px;
          padding: 26px 24px;
          border-radius: 5px;
          z-index: 2;
        }

        .slide-image {
          order: 2;
          max-width: 90%;
          margin: -44px auto 80px;
          z-index: 3;
        }
      }
    }
  }

  .slide-controls {
    max-width: 238px;
    &::before {
      content: "";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      right: 0;
      height: 2px;
      background-color: #000;
    }
    .slide-control {
      width: 18px;
      height: 18px;
      border-radius: 50px;
    }
  }

}
</style>
