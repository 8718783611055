<template>
  <div class="welcome-section">
    <div class="welcome-section-grid relative mx-auto">
      <div class="welcome-section-header">
        <h1 class="mb-8 font-weight-regular black--text">
          Welcome to your
          <strong class="d-block font-weight-black accent--text">Modern <br> Design Space</strong>
        </h1>

        <p v-if="$vuetify.breakpoint.smAndUp" class="mb-0 text-body-md black--text">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Et ut rutrum viverra amet justo. Egestas morbi vel imperdiet sed semper. Senectus ac eget
        </p>

        <template v-else>
          <p class="mb-0 text-body-md black--text">
            Lorem ipsum dolor sit amet, consectetur adipiscing
          </p>

          <div class="d-flex align-center justify-center mt-8">
            <UiBtn color="accent" width="236" height="42" :to="{name: 'Login'}">Get Started</UiBtn>
          </div>
        </template>
      </div>

      <div class="overflow-hidden">
        <div class="welcome-section-img d-flex align-center ml-auto">
          <img :src="require('@/assets/images/landings/welcomeLanding/modern-design-space.png')" alt="" class="fill-width">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UiBtn from "@/components/UI/UiBtn";

export default {
  name: "WelcomeSection",
  components: {
    UiBtn,
  },
}
</script>

<style scoped lang="scss">
.welcome-section {
  padding: 116px 40px 30px;

  @media #{map-get($display-breakpoints, 'md-and-down')} {
    padding-top: 96px;
  }

  @media #{map-get($display-breakpoints, 'xs-only')} {
    padding: 32px 0 32px;
  }

  .welcome-section-grid {
    max-width: 1384px;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      bottom: -83px;
      height: 171px;
      width: 588px;
      background: transparent url("~@/assets/images/landings/welcomeLanding/welcome-section-connector.svg") no-repeat center center;
      background-size: contain;
      z-index: 2;

      @media #{map-get($display-breakpoints, 'xs-only')} {
        left: 50%;
        transform: translateX(-50%);
        bottom: -56px;
        width: 2px;
        height: 91px;
        background: transparent url("~@/assets/images/landings/welcomeLanding/vertical-line.svg") no-repeat center center;
      }
    }
  }

  .welcome-section-header {
    position: absolute;

    h1 {
      font-size: 64px;
      line-height: 65px;

      strong {
        font-size: 96px;
        line-height: 97px;
      }
    }

    p {
      max-width: 286px;
    }

    @media #{map-get($display-breakpoints, 'md-and-down')} {
      h1 {
        font-size: 54px;
        line-height: 55px;

        strong {
          font-size: 70px;
          line-height: 71px;
        }
      }
    }

    @media #{map-get($display-breakpoints, 'xs-only')} {
      position: relative;
      padding: 0 20px 0;
      h1 {
        font-size: 32px;
        line-height: 33px;

        strong {
          font-size: 48px;
          line-height: 49px;
        }
      }
    }
  }

  .welcome-section-img {
    max-width: 100%;
    height: 522px;
    padding-left: 308px;
    margin-right: -100px;

    img {
      margin-top: -90px;
    }

    @media #{map-get($display-breakpoints, 'md-only')} {
      padding-left: 100px;

      img {
        margin-top: -60px;
      }
    }

    @media #{map-get($display-breakpoints, 'sm-only')} {
      padding-left: 0;

      img {
        margin-top: 154px;
      }
    }

    @media #{map-get($display-breakpoints, 'xs-only')} {
      padding-left: 0;
      margin-right: 0;
      height: 480px;

      img {
        margin-top: 0;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
</style>
